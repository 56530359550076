.title {
    background-color: var(--clr-theme);
    max-width: fit-content;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.active_admin {
    background-color: #36d200;
    color: #fff;
    border-radius: 1rem;
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
    display: inline-block;
}

.inactive_admin {
    background-color: #f83838;
    color: #fff;
    border-radius: 1rem;
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
    display: inline-block;
}

/* popup */
.dialog_title {
    font-weight: bold;
    margin: auto;
}

.employee_gl_popup {
    background-color: #fff;
    font-size: 1rem;
    padding: 0.7rem 2rem;
    border-radius: 0.3rem;
    margin: auto;
    text-align: center;
    width: 50%;
    margin-top: 1.5rem;
    padding: 0.8rem 1rem;
    cursor: pointer;
    color: #000;
    border: 2px solid #000;
}

.employee_gl_popup_del {
    background-color: red;
    color: #fff;
    font-size: 1rem;
    padding: 0.7rem 2rem;
    border-radius: 0.3rem;
    margin: auto;
    text-align: center;
    width: 50%;
    margin-top: 1.5rem;
    padding: 0.8rem 1rem;
    cursor: pointer;
}

.employee_gl_popup_del:hover {
    background-color: #a00808;
}

.admin_container {
    padding: 2rem 4rem;
    overflow: inherit !important;
}

.form-login-btn {
    padding-top: 3rem;
    width: 25%;
    margin: auto;
}

.pagination {
    display: grid;
    place-items: center;
    margin-block: 1rem;
}

.main-heading-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-box {
    align-items: center;
    background-color: #d5d5d522;
    border-radius: 4px;
    color: #777;
    display: flex;
    flex-grow: 1;
    padding: 10px 5px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.search-box input {
    background-color: initial;
    border: none;
    color: #777;
    flex-grow: 1;
    font-size: 18px;
    outline: none;
}

.user-list-heading {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-block: 0rem 1rem;
    align-items: self-end;
    height: 6rem;
    column-gap: 1rem;
}

.filter-btn {
    background-color: var(--clr-primary);
    color: white;
    max-width: fit-content;
    padding: 0.3rem 1rem;
    border-radius: 10px;
    cursor: pointer;
}

.user-list-search {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.article-list-title {
    margin-block: 0.6rem;
}


.add_btn {
    padding: 3px 3px;
    border: 1px solid gray;
    border-radius: 5px;
    font-size: small;
}

.delete_btn {
    padding: 3px 3px;
    border: 1px solid gray;
    border-radius: 5px;
    font-size: small;
}

.action_btn {
    display: flex;
    column-gap: 2px;
    justify-content: center;
}


.filter {
    /* background-color: var(--clr-primary); */
    /* background-color: rgb(193, 202, 208); */
    border: 2px solid;
    border-color: var(--clr-theme);
    display: flex;
    flex-direction: column;
    max-width: 60%;
    font-size: 15px;
    /* padding: 0.1rem .5rem; */
    border-radius: 5px;
    line-height: 28px;

}

.filter input {
    width: 103%;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
}

.filter select {
    width: 95%;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
}

.btn {
    padding: 5px 1.3rem;
    border: 2px solid black;
    border-radius: 5px;
    background-color: var(--clr-theme);
    color: #fff;
}

@media screen and (max-width: 768px){
    .admin_container {
        padding: 1rem !important;
    }
    .form-login-btn {
        padding-top: 1rem !important;
        width: 40% !important;
        margin: auto;
    }
}

.css-1nmdiq5-menu{
    z-index: 999 !important;
}